import React from 'react';
import pantanal from '../assets/pantanal.png';
import styled from 'styled-components';
import { getCopy } from '../helpers/copy';
import { trackUsage } from '../helpers/track';
import { ConstrainedContainer } from './Container';
import { Language } from '../types';

const OutsideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  color: white;

  margin: 24px 0;
  box-sizing: border-box;

  width: 100%;

  padding: 20px;

  @media (max-width: 800px) {
    padding: 20px 0;
  }
`;

const PantanalContainer = styled(ConstrainedContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 16px;

  margin: 12px 0;

  @media (max-width: 800px) {
    font-size: 14px;
    max-width: 80%;
  }
`;

const Mini = styled.span`
  font-size: 10px;
  vertical-align: super;

  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

const PantanalImage = styled.img`
  height: 96px;
  align-self: start;
`;

const PantanalInfo = styled.div`
  margin-left: 24px;
`;

const Help = styled.a`
  color: white;
  font-weight: bold;
`;

type PantanalProps = {
  language: Language;
};

export default ({ language }: PantanalProps) => (
  <OutsideContainer>
    <PantanalContainer>
      <PantanalImage src={pantanal} />
      <PantanalInfo>
        <div>
          {getCopy('pantanal_info', language) + ' '} <Mini>[4][5]</Mini>
        </div>
        <div style={{ marginTop: '10px' }}>
          {getCopy('pantanal_help', language)}{' '}
          <Help
            href="https://www.sospantanal.org.br/doacoes/?utm_source=hotsite&utm_medium=button&utm_campaign=quantos_jacares"
            target="_blank"
            onClick={() => trackUsage('clicked_donate_sos_pantanal')}
          >
            {' ' + getCopy('here', language)}
          </Help>
          . {' **'}
        </div>
      </PantanalInfo>
    </PantanalContainer>
  </OutsideContainer>
);
