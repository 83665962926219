import { getCopy } from '../helpers/copy';
import React from 'react';
import styled from 'styled-components';
import { trackUsage } from '../helpers/track';
import { ConstrainedContainer } from './Container';
import { Language } from '../types';

const References = styled(ConstrainedContainer)`
  line-height: 24px;
  font-size: 16px;

  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

const Reference = styled.div``;

type ReferencesProps = {
  language: Language;
};

export default ({ language }: ReferencesProps) => (
  <References>
    <Reference>
      [1]{' ' + getCopy('source_from', language) + ' '}
      <a
        href="https://ourworldindata.org/grapher/cumulative-covid-vaccinations?tab=chart&stackMode=absolute&time=2020-12-26..latest&region=World"
        target="_blank"
        onClick={() => trackUsage('track_click_reference_1')}
      >
        Our World in Data
      </a>
      . {getCopy('increase_rate', language)}.
    </Reference>
    <Reference>
      [2] {getCopy('vacinometro', language)}{' '}
      <a href="https://coronavirusbra1.github.io/" target="_blank">
        CoronavirusBra1
      </a>
    </Reference>
    <Reference>
      [3]{' '}
      <a
        href="https://www.foxnews.com/world/brazilian-president-rhetorically-claims-coronavirus-vaccine-could-turn-people-into-alligators"
        target="_blank"
        onClick={() => trackUsage('track_click_reference_2')}
      >
        {getCopy('bolsonaro_claim', language)}
      </a>
      , {getCopy('bolsonaro_claim_date', language)}. (
      <a
        href="https://www.youtube.com/watch?v=PcfAjTR8UgM"
        onClick={() => trackUsage('track_click_reference_2')}
        target="_blank"
      >
        video
      </a>
      )
    </Reference>
    <Reference>
      [4]{' '}
      <a
        href="https://lasa.ufrj.br/noticias/area-queimada-pantanal-2020/"
        target="_blank"
        onClick={() => trackUsage('track_click_reference_3')}
      >
        {getCopy('pantanal_reference', language)}
      </a>
    </Reference>
    <Reference>
      [5]{' '}
      <a
        href="https://www1.folha.uol.com.br/ambiente/2020/11/pantanal-tem-pior-outubro-de-queimadas-da-historia-e-fogo-cresce-121-na-amazonia.shtml"
        target="_blank"
        onClick={() => trackUsage('track_click_reference_4')}
      >
        {getCopy('pantanal_reference_two', language)}
      </a>
    </Reference>
    <Reference>* {getCopy('not_including_trials', language)}.</Reference>
    <Reference>** {getCopy('not_sos_pantanal', language)}</Reference>
  </References>
);
