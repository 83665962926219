import styled from 'styled-components';

export const ConstrainedContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  max-width: 800px;

  margin: 24px 0;

  @media (max-width: 800px) {
    max-width: 83%;
  }
`;
