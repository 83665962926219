export enum Language {
  EN = 'en',
  ES = 'es',
  PT_BR = 'pt-br',
}

export type TrackerPlaceData = {
  lastUpdatedAt: string;
  collectionTimestamp: string;
  peopleVaccinated: number;
  dosesApplied: number;
  dailyIncreaseRate?: number;
};

export type TrackerData = {
  brazil: TrackerPlaceData;
  world: TrackerPlaceData;
  alligators: number;
};

export type Response = {
  tracker: TrackerData;
  supply?: any;
};
