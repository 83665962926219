import { TrackerData, TrackerPlaceData } from '../types';

const manualData: TrackerData = {
  brazil: {
    lastUpdatedAt: '2021-03-23T14:00:41.000Z',
    collectionTimestamp: '2021-03-23T14:00:41.000Z',
    peopleVaccinated: 12403415,
    dosesApplied: 16673555,
  },
  world: {
    lastUpdatedAt: '2021-03-22T00:00:00.000Z',
    collectionTimestamp: '2021-03-23T14:00:45.000Z',
    peopleVaccinated: 271124586,
    dosesApplied: 458132045,
    dailyIncreaseRate: 12277694.5,
  },
  alligators: 0,
};

export class VaccinationAPI {
  http;

  constructor(http = fetch) {
    this.http = http;
  }

  async getData(): Promise<TrackerData> {
    try {
      // const allData = await fetch('https://api.jacaretracker.com/latest', {
      //   // const allData = await fetch('http://localhost:8080/latest', {
      //   headers: {
      //     'client-token': '44e1e0ec-d114-4a6d-87cd-e6454befb2fb',
      //   },
      // });
      return {
        brazil: {
          collectionTimestamp: Date.now().toString(),
          dosesApplied: 433023958,
          lastUpdatedAt: Date.now().toString(),
          peopleVaccinated: 189644563,
          dailyIncreaseRate: 0,
        },
        world: 
          {
            collectionTimestamp: Date.now().toString(),
            dosesApplied: 13530000000,
            lastUpdatedAt: Date.now().toString(),
            peopleVaccinated: 5576975929,
            dailyIncreaseRate: 0,
          },
        alligators: 0,
      };
    } catch (e) {
      return manualData;
    }
  }
}

export function recalculateLatestFigure(placeData: TrackerPlaceData, nowTimestamp = new Date().getTime()): number {
  if (!placeData.dailyIncreaseRate) {
    return placeData.peopleVaccinated;
  }

  const lastTimestamp = new Date(placeData.lastUpdatedAt).getTime();

  const timeElapsedInMilli = nowTimestamp - lastTimestamp;
  const perMilliRate = placeData.dailyIncreaseRate / 24 / 60 / 60 / 1000;

  const total = placeData.peopleVaccinated + timeElapsedInMilli * perMilliRate;

  return Math.round(total);
}
