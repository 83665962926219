import { Language } from '../types';

export function asNumber(value: string | number): number {
  return typeof value === 'number' ? value : parseInt(value);
}

export function capitalise(word: string) {
  return word[0].toUpperCase() + word.substr(1);
}

export function getLanguage(): Language {
  if (window.location.pathname.indexOf('en') > -1) {
    return Language.EN;
  }

  if (window.location.pathname.indexOf('es') > -1) {
    return Language.ES;
  }

  return Language.PT_BR;
}
