import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  margin-top: 120px;
`;

export default function Loading() {
  return <LoadingContainer>🐊 Loading</LoadingContainer>;
}
