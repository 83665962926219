import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import 'moment/locale/pt-br';

import References from './components/References';
import Data from './components/Data';
import Pantanal from './components/Pantanal';
import Social from './components/Social';
import Loading from './components/Loading';

import { recalculateLatestFigure, VaccinationAPI } from './services/api';
import { getCopy } from './helpers/copy';
import { Language } from './types';
import { capitalise, getLanguage } from './helpers/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 1;
  width: 100%;

  border-top: 10px solid var(--green);
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin: 36px 0 24px;

  font-size: 36px;

  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

export default () => {
  const [worldTotal, setWorldTotal] = useState<number>();
  const [brazilTotal, setBrazilTotal] = useState<number>();
  const [language, setLanguage] = useState<Language>();

  useEffect(() => {
    let interval: any;
    async function loadData() {
      const vaccinationAPI = new VaccinationAPI();
      const trackerData = await vaccinationAPI.getData();

      setWorldTotal(recalculateLatestFigure(trackerData.world));
      setBrazilTotal(trackerData.brazil.peopleVaccinated);

      interval = setInterval(() => {
        setWorldTotal(recalculateLatestFigure(trackerData.world));
        setBrazilTotal(trackerData.brazil.peopleVaccinated);
      }, 500);
    }
    setLanguage(getLanguage());

    loadData();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      {worldTotal && brazilTotal && language ? (
        <InnerContainer>
          <Helmet>
            <title>{getCopy('title', language)}</title>
            <meta name="description" content={getCopy('description', language)} />
          </Helmet>
          <DateContainer>
            <h3>{capitalise(moment().locale(language).format('dddd'))}</h3>
            <h5>{moment().locale(language).format('LL')}</h5>
            {language === 'en' ? (
              <a style={{ fontSize: '16px', marginTop: '8px' }} href="https://jacaretracker.org">
                Versão em português
              </a>
            ) : (
              <a style={{ fontSize: '16px', marginTop: '8px' }} href="https://jacaretracker.org/en">
                English version
              </a>
            )}
          </DateContainer>
          <Data language={language} worldTotal={worldTotal} brazilTotal={brazilTotal} />
          <Social />
          <Pantanal language={language} />
          <References language={language} />
        </InnerContainer>
      ) : (
        <Loading />
      )}
    </Container>
  );
};
