import React from 'react';
import styled from 'styled-components';
import twitter from '../assets/twitter.png';
import instagram from '../assets/instagram.png';

const Social = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  font-size: 16px;
  margin: 6px;

  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

const Twitter = styled.img`
  width: 50px;
  margin-bottom: 12px;
`;

const Instagram = styled.img`
  width: 45px;
  margin-bottom: 10px;
`;

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 12px;
`;

export default () => (
  <Social>
    <Link href="https://twitter.com/jacaretracker" target="_blank">
      <Twitter src={twitter} />
      @jacaretracker
    </Link>
    <Link href="https://www.instagram.com/jacaretrackeroficial/" target="_blank">
      <Instagram src={instagram} />
      @jacaretrackeroficial
    </Link>
  </Social>
);
