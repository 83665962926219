import React from 'react';
import styled from 'styled-components';

import flagBR from '../assets/flag-br.png';
import alligator from '../assets/alligator.png';
import worldMeridian from '../assets/world-meridian.png';

import { getCopy } from '../helpers/copy';
import { ConstrainedContainer } from './Container';
import { Language } from '../types';

const Data = styled(ConstrainedContainer)`
  display: flex;
  align-items: start;
  flex-direction: column;

  font-size: 36px;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

const Mini = styled.span`
  font-size: 16px;
  vertical-align: super;
`;

const Highlight = styled.div`
  display: inline-block;
  vertical-align: middle;

  background-color: rgba(56, 124, 74, 0.3);
  border: 1px solid var(--green);
  font-weight: bold;

  padding: 0 18px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 4px;

  font-size: 42px;
  @media (max-width: 800px) {
    padding: 0 12px;
    font-size: 26px;
  }
`;

const LengthyHighlight = styled(Highlight)`
  display: inline-block;
  text-align: left;
  width: 335px;

  @media (max-width: 800px) {
    width: 205px;
  }
`;

const DataCopy = styled.span`
  line-height: 48px;

  @media (max-width: 800px) {
    line-height: 30px;
  }
`;

const Icon = styled.img`
  width: 42px;
  margin-right: 12px;
  vertical-align: middle;

  @media (max-width: 800px) {
    width: 32px;
  }
`;

const Item = styled.div`
  margin-bottom: 14px;
`;

type DataProps = {
  language: Language;
  worldTotal: number;
  brazilTotal: number;
};

export default ({ language, worldTotal, brazilTotal }: DataProps) => (
  <Data>
    <Item>
      <Icon src={worldMeridian} />
      <LengthyHighlight>{Number(5847302100).toLocaleString(language)}</LengthyHighlight>
      <DataCopy>{' ' + getCopy('vaccinated_worldwide', language)}</DataCopy>
      <Mini> [1]</Mini>
    </Item>
    <Item>
      <Icon src={flagBR} />
      <Highlight>{Number(brazilTotal).toLocaleString(language)}</Highlight>
      <DataCopy>{' ' + getCopy('vaccinated_brazil', language)}</DataCopy>
      <Mini> [2]*</Mini>
    </Item>
    <Item>
      <Icon src={alligator} />
      <Highlight>0</Highlight>
      <DataCopy>{' ' + getCopy('turned_alligators', language)}</DataCopy>
      <Mini> [3]</Mini>
    </Item>
  </Data>
);
