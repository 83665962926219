import { Language } from '../types';

type Translation = {
  title: string;
  description: string;
  vaccinated_worldwide: string;
  turned_alligators: string;
  vaccinated_brazil: string;
  source_from: string;
  increase_rate: string;
  bolsonaro_claim: string;
  bolsonaro_claim_date: string;
  not_including_trials: string;
  pantanal_help: string;
  here: string;
  pantanal_info: string;
  pantanal_reference: string;
  pantanal_reference_two: string;
  not_sos_pantanal: string;
  vacinometro: string;
  pace: string;
};

type Translations = {
  [lang: string]: Translation;
};

const translations: Translations = {
  [Language.EN]: {
    title: 'How many alligators?',
    description:
      'Brazilian President Jair Bolsonaro claims Pfizer vaccine could turn you into alligators. Keep track of how many times it has happened.',
    vaccinated_worldwide: 'people vaccinated against COVID-19 worldwide',
    turned_alligators: 'people turned into alligators',
    vaccinated_brazil: 'people vaccinated against COVID-19 in Brazil',
    source_from: 'Source from',
    increase_rate:
      'Increase rate relative to change between the last 5 days of reported data. Considering people that received at least one dose.',
    bolsonaro_claim: 'Bolsonaro claims COVID-19 vaccines could turn people into alligators',
    bolsonaro_claim_date: 'Dec 18th 2020',
    not_including_trials:
      'Not including data for people in vaccine trials. Considering people that received at least one dose.',
    pantanal_help: 'You can help Pantanal, find out more',
    here: 'here',
    pantanal_info:
      "Meanwhile the wildlife suffers as Brazil's Pantanal burns. The fires in the Pantanal in 2020 have taken almost 45 thousand km² of the biome, endangering 41 species of reptiles, amongst many others.",
    pantanal_reference: 'LASA (UFRJ) - Laboratory for Environmental Satellite Applications',
    pantanal_reference_two: 'Fires in Pantanal are the worst in history',
    not_sos_pantanal: "jacaretracker.org is an independent initiative and it's not associated with SOS Pantanal.",
    vacinometro: 'Additional data from',
    pace: 'In this pace Brazil would vaccinate 70% of the population by',
  },
  [Language.PT_BR]: {
    title: 'Quantos jacarés?',
    description:
      'Presidente do Brasil comenta que vacina da Pfizer pode transformar pessoas em jacarés. Acompanhe aqui caso a caso.',
    vaccinated_worldwide: 'pessoas vacinadas contra a COVID-19 no mundo',
    turned_alligators: 'pessoas que viraram jacaré',
    vaccinated_brazil: 'pessoas vacinadas contra a COVID-19 no Brasil',
    source_from: 'Referência: ',
    increase_rate:
      'Taxa de aumento estimada a partir dos dados dos últimos 5 dias divulgados. Considerando pessoas que receberam ao menos uma dose.',
    bolsonaro_claim: 'Bolsonaro sobre a vacina da Pfizer: "Se você virar jacaré, é problema seu"',
    bolsonaro_claim_date: 'em 18 de Dezembro de 2020',
    not_including_trials:
      'Não inclui pessoas vacinadas durante testes de vacinas. Considerando pessoas que receberam ao menos uma dose.',
    pantanal_info:
      'Enquanto isso, os nossos jacarés sofrem. As queimadas no Pantanal levaram quase 45 mil km² do bioma, ameaçando 41 espécies de répteis, entre muitas outras.',
    pantanal_help: 'Você pode ajudar o Pantanal, descubra mais',
    here: 'aqui',
    pantanal_reference: 'LASA (UFRJ) - Laboratório de Aplicações de Satélites Ambientais',
    pantanal_reference_two: 'Incêndio no Pantanal é o pior da história',
    not_sos_pantanal: 'jacaretracker.org é uma iniciativa independente e NÃO está vinculada ao SOS Pantanal.',
    vacinometro: 'Dados adicionais do',
    pace: 'No ritmo de vacinação atual, o Brasil terminaria de vacinar 70% da população em',
  },
};

export function getCopy(key: string, language: Language = Language.EN): string {
  // @ts-ignore
  return translations[language][key] || translations[Language.EN][key] || key;
}
